import React from 'react';
import ResumeEntry from '../../components/resume/ResumeEntry'

import './page2.css';

const Page2 = () => {
    return(
        <>
            <div className = "page about-page">
                <div className= "container">
                    <div className = "left-side">
                        <div className = "left-side-top">
                            <h2>About me</h2>
                            <h3></h3>
                            <p>Hello! I'm Jan Stuers, a student of applied computer science at the Ruhr West University of Applied Sciences. I am particularly interested in cyber security, which is why I am also pursuing a career in this field. In my free time I like to spend time with my dog, play computer games and realise my own small computer science projects.</p>
                        </div>
                        <div className ="left-side-bottom">
                        <h2>Education</h2>
                            <h3>Studied at</h3>
                            <ResumeEntry 
                                className="entry" 
                                name='Gymnasium Heißen' 
                                date='2011-2019' 
                                details='Abitur'
                            />
                            <br/>
                            <hr/>
                            <ResumeEntry
                                className="entry" 
                                name='Hochschule Ruhr West' 
                                date='2019-2023'
                                details='Bachelor of Science (B.Sc)'
                            />
                        </div>
                    </div>
                    <div className = "mid-border"/>
                    <div className = "right-side">
                        <div className='right-top'/>
                        <div className='right-bottom'>
                            <h2>Experience</h2>
                            <h3>University projects</h3>
                            <ResumeEntry
                                className="entry" 
                                name='Room booking system for HRW' 
                                date='2021/2022'
                                details={'As part of module \'Projectmanagement (Computer Science Project)\' Worked in group \'Project planning/organisation group\' and \'Ease of use\''}
                            />
                            <br/>
                            <hr/>
                            <ResumeEntry
                                className="entry" 
                                name='App for linking social media profiles' 
                                date='2021/2022'
                                details='As part of module &#8242;Mobile computing&#8242; (React Native, Firebase)'
                            />
                            <br/>
                            <hr/>
                            <ResumeEntry
                                className="entry" 
                                name='Deadlock simulation environment' 
                                date='2021'
                                details='As part of module &#8242;Operating Systems&#8242; (C/C++, Qt)'
                            />
                            <br/>
                            <hr/>

                            <hr/>
                            <h3>Work experience</h3>
                            <ResumeEntry
                                className="entry" 
                                name='Siemens Energy AG' 
                                date='April-September 2023'
                                details='Software Developer for Process Automation in Order Management'
                            />
                            <br/>
                        </div>
                        
                    </div>
                </div>
                </div>
        </>
    )
}
export default Page2;