import React from 'react';
import {useRef, useEffect} from 'react';



import Card from '../../components/cards/Card.js';

import './page3.css';

import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const Page3 = () => {
    const cardRef1 = useRef(null);
    const cardRef2 = useRef(null);
    const slideRef = useRef(null);

    // useEffect(() => {
    //     gsap.fromTo(cardRef1.current,{x:'100vw'},{
    //         x:0,
    //         ease:'power2',
    //         duration:'1.5',
    //         scrollTrigger:{
    //             trigger:slideRef.current,
    //             start:"top center",
    //         }
    //     });
    //     gsap.fromTo(cardRef2.current,{x:'-100vw'},{
    //         x:0,
    //         ease:'power2',
    //         duration:'1.5',
    //         scrollTrigger:{
    //             trigger:slideRef.current,
    //             start:"top center",
    //         }
    //     });
    //     //gsap.fromTo(cardRef2.current,{x:'100vw'},{x:0,ease:'power2',duration:1.5});
    // },[]);
    
    return(
        <div className="slide-container" ref={slideRef}>
            <div className = "page skill-page">
                <h2>Skills</h2>
                <div className='skills-container'>
                    <div className="card-container" ref={cardRef1}>
                        <div className="card-box">
                            <Card className="card-1" title="Programming languages" description="Java, Javascript, C/C++, Python, (PHP)" />
                        </div>
                        <div className="card-box">
                            <Card className="card-2" title="Frameworks" description="Qt, Node.js, JUnit" />
                        </div>
                        <div className="card-box">
                            <Card className="card-3" title="Databases" description="MySQL, MongoDB, Firebase" />
                        </div>
                    </div>
                    
                    <div className="card-container" ref={cardRef2}>
                        <div className="card-box">
                            <Card className="card-4" title="DevOps" description="Jira, GitLab" />
                        </div>
                        <div className="card-box">
                            <Card className="card-5" title="Network tools" description="Wireshark" />
                        </div>
                        <div className="card-box">
                            <Card className="card-6" title="Other" description={"Markup languages: HTML 5, CSS \n Libraries: ReactJS , React Native \n Operating systems: Linux \n Software: Matlab"} />
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        
    )
}
export default Page3;


/*

*/