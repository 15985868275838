import React from 'react';
import Contact from '../../components/contact/Contact';
import AltContact from '../../components/altContact/AltContact';

import './page4.css';

const Page4 = () => {
  return(
    <div className = "page contact-page">
      {/*<Contact/>*/}
      <AltContact/>
    </div>
    )
}
export default Page4;