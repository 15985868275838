import React from 'react';
import {useState} from 'react';

import {motion} from 'framer-motion';

import './Card.css';


const Card = (props) => {
    const [isOpen,setIsOpen] = useState(false);
    const title = props.title;
    const formattedTitle = title.split('\n').map(str=> <p>{str}</p>);
    const description = props.description;
    const formattedDescription = description.split('\n').map(str=> <p>{str}</p>)
    return(
        <>
            <motion.div 
                className="card"
                transition={{layout:{duration:1, type: "spring"}}}
                layout
                onHoverStart={()=>setIsOpen(true)} 
                onHoverEnd={()=>setIsOpen(false)}
                onClick={()=>setIsOpen(isOpen === true ? false : true)}
            >
                <motion.div className="card__title" layout="position" >
                    <h5>{formattedTitle}</h5>
                </motion.div>
                {isOpen && (
                    <motion.div 
                        className="card__expand"
                        initial={{opacity:0}}
                        animate={{opacity:1}}
                        transition={{duration:1}}
                        
                    >
                        <p className="card__description">{formattedDescription}</p>
                    </motion.div>
                )}       
            </motion.div>
        </>
    )
}
export default Card;