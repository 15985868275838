import React from 'react';

import './ResumeEntry.css';

const ResumeEntry = (props) => {
  return(
    <div className ="resume-container">
      <h4 className='resume-headline'>{props.name}</h4>
      <p>{props.date}</p>
      <p>{props.details}</p>
    </div>
  )
}

export default ResumeEntry;