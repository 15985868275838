import React, { useEffect, useState, useRef } from "react";
import {FaBars, FaTimes} from "react-icons/fa";
import { Link } from "react-scroll";

import './NavBar.css';

const NavBar = () => {
    const navRef= useRef();
    const showNavbar = () => {
        navRef.current.classList.toggle("responsive_nav");
    }

    const [navColor, setNavColor] = useState("transparent");
    const scrollEvent = () => {
        window.scrollY > 20 ? setNavColor("white"):setNavColor("transparent");
    }

    useEffect(() => {
        window.addEventListener("scroll",scrollEvent);
        return () => {
            window.removeEventListener("scroll", scrollEvent);
        };
    }, []);
    
    return(
        <header className='NavBar' style={{backgroundColor:navColor}}>
            <nav ref={navRef}>
                <Link activeClass="active" smooth spy to="home" onClick={showNavbar}>Home</Link>
                <Link activeClass="active" smooth spy to="about" onClick={showNavbar}>About&nbsp;me</Link>
                <Link activeClass="active" smooth spy to="skills" onClick={showNavbar}>Skills</Link>
                <Link activeClass="active" smooth spy to="contact" onClick={showNavbar}>Contact</Link>
                <button className="nav-btn nav-close-btn" onClick={showNavbar}>
                    <FaTimes/>
                </button>
            </nav>
            <button className="nav-btn" onClick={showNavbar}>
                <FaBars/>
            </button>
        </header>

            
        
    )
}

export default NavBar;

/*
<header className='NavBar' style={{backgroundColor:navColor}}>
<div className="nav-items">
                <Link activeClass="active" smooth spy to="home">Home</Link>
                <Link activeClass="active" smooth spy to="about">About me</Link>
                <Link activeClass="active" smooth spy to="skills">Skills</Link>
                <Link activeClass="active" smooth spy to="contact">Contact</Link>
                <label htmlFor="chk" className="show-menu-btn">
                    <i className="fas fa-bars"/>
                </label>

                <ul className="menu">
                    <Link activeClass="active" smooth spy to="home">Home</Link>
                    <Link activeClass="active" smooth spy to="about">About me</Link>
                    <Link activeClass="active" smooth spy to="skills">Skills</Link>
                    <Link activeClass="active" smooth spy to="contact">Contact</Link>
                    <label htmlFor="chk" className="hide-menu-bar">
                        <i className="fas fa-times"/>
                    </label>
                </ul>
            </div>
            <div className="nav-toggle">
                <div className="bar"></div>
            </div>
            </header>
*/